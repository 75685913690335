<script setup lang="ts">
import { useKeenSlider } from 'keen-slider/vue';
import { vMaybeRouterLink } from '~~/directives/router';
import { keenAutoplay } from '~~/utils/keen-slider';
import { SettingAttrs } from '~~/utils/models/Setting';
import IconArrowLeft from '~~/assets/svg/icons/arrow-left.svg';
import IconArrowRight from '~~/assets/svg/icons/arrow-right.svg';
import IconArrowRight2 from '~~/assets/svg/icons/arrow-right-2.svg';
import IconChevronDown from '~~/assets/svg/icons/chevron-down.svg';

const handleScrollClick = () => {
  const offset = document.querySelector('.site-header')?.clientHeight || 0;
  const height = el.value?.clientHeight || 0;
  const top = height - offset;

  window.scrollTo({ top, behavior: 'smooth' });
};

const props = defineProps<{ banner: SettingAttrs }>();
const length = computed(() => props.banner.value?.items.length || 0);
const el = ref<HTMLElement>();
const activeIndex = ref(0);
const [keenSlider, keen] = useKeenSlider(
  {
    loop: true,
    disabled: length.value === 1,
    slideChanged: (keen) => {
      activeIndex.value = keen.track.details.rel;
    },
  },
  [keenAutoplay]
);

onMounted(() => {
  el.value?.addEventListener('contextmenu', (event) => event.preventDefault());
});
</script>

<template>
  <section ref="el" class="relative">
    <div ref="keenSlider" class="keen-slider">
      <div
        class="h-item keen-slider__slide min-w-full max-h-screen aspect-[375/480] sm:aspect-[1440/732]"
        v-for="(item, i) of banner.value?.items"
        :key="i"
      >
        <LazyBannerWideItemImage
          class="u-photo"
          v-if="item.type === 'IMAGE'"
          :lazyImage="i > 0"
          :item="item"
          :isActive="i === activeIndex"
        />
        <LazyBannerWideItemVideo
          v-else-if="item.type === 'VIDEO'"
          :item="item"
          :isActive="i === activeIndex"
        />

        <div class="container absolute inset-x-0 bottom-[108px]">
          <p
            class="p-name text-20 lg:text-40 leading-32 lg:leading-56 font-black uppercase w-[224px] lg:w-[483px] mb-24 lg:mb-32"
            v-if="item.caption"
          >
            {{ item.caption }}
          </p>
          <a
            class="u-url btn btn-primary btn-163x40 lg:btn-240x48 gap-12"
            target="_blank"
            rel="noopener"
            v-if="item.button && item.url"
            :href="item.url"
          >
            {{ item.button }}
            <IconArrowRight2 />
          </a>
        </div>
      </div>
    </div>

    <div class="scroll-wrapper text-center py-8 absolute inset-x-0 bottom-0">
      <button
        class="btn-plain text-14 leading-24 font-medium"
        @click="handleScrollClick"
      >
        View more<br />
        <IconChevronDown />
      </button>
    </div>

    <div class="container absolute inset-x-0 bottom-0" v-if="length > 1">
      <KeenNavDots
        class="absolute left-16 xl:left-32 bottom-[62px]"
        :active-index="activeIndex"
        :length="length"
        @dot-click="keen?.moveToIdx($event)"
      />

      <div
        class="grid gap-16 absolute right-[32px] bottom-[108px] sm:bottom-[116px]"
      >
        <button
          class="btn py-6 px-10 lg:p-16 bg-white/10 backdrop-blur-[11px]"
          aria-label="Prev slide"
          @click="keen?.prev()"
        >
          <IconArrowLeft class="w-20 lg:w-auto" />
        </button>
        <button
          class="btn py-6 px-10 lg:p-16 bg-white/10 backdrop-blur-[11px]"
          aria-label="Next slide"
          @click="keen?.next()"
        >
          <IconArrowRight class="w-20 lg:w-auto" />
        </button>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
@keyframes down-up {
  0%,
  100% {
    transform: translateY(-4px);
  }

  50% {
    transform: translateY(4px);
  }
}

.scroll-wrapper {
  background: linear-gradient(180deg, rgba(6, 7, 16, 0) 0%, #060710 91.15%);

  svg {
    animation: down-up 1.5s ease infinite;
  }
}
</style>
